import React, { useState } from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-customWhite shadow-md ">
      <div className="container mx-auto flex justify-between items-center p-4 h-20">
        <div className="flex-shrink-0">
          <a href="https://lytblbtechnology.com">
            <img
              src="Main_Banner/Logo.png"
              alt="Logo"
              className="h-16 ml-8 mb-2" 
            />
          </a>
        </div>
        <nav className="hidden md:flex space-x-4 gap-8 ">
          <Link to="who" className="text-customBlack  cursor-pointer hover:text-customBlack text-xl font-semibold">Who we are</Link>
          <Link to="services" className="text-customBlack   cursor-pointer hover:text-customBlack  text-xl font-semibold">Our Services</Link>
          <Link to="ourclients" className="text-customBlack cursor-pointer hover:text-customBlack text-xl font-semibold">Our Clients</Link>
          <Link to="contactus"className="text-customBlack text-xl cursor-pointer hover:text-customBlack font-semibold">Contact Us</Link>
        </nav>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-gray-700 focus:outline-none">
            {isOpen ? (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="md:hidden bg-white shadow-md ">
          <Link to="who" className="block px-4  cursor-pointer font-medium text-customBlack hover:bg-customgrey">Who We Are</Link>
          <Link to="services" className="block cursor-pointer font-medium px-4  text-customBlack hover:bg-customgrey">Our Services</Link>
          <Link to="ourclients" className="block px-4 font-medium cursor-pointerpy-2 text-customBlack hover:bg-customgrey">Our Clients</Link>
          <Link to="contactus" className="block px-4 font-medium cursor-pointer text-customBlack hover:bg-customgrey">Contact Us</Link>
        </nav>
      )}
    </header>
  );
};

export default Navbar;