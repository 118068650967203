import React from 'react';
import Header from './components/Header';
import Nav from './components/Nav';
import Banner from './components/Banner';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Clients from './components/Clients';
import Works from './components/Works';
import Contact from './components/Contact';
import CustomForm from './components/Updates';
import Footer from './components/Footer';
 
const App = () => {
  return (
    <div>
      <Header />
      <Nav/>

      <Banner/>
      <AboutUs />
      <Services />
      <Clients />
      <Works/>
      <Contact/>

      <CustomForm/>
      <Footer/>
      
    </div>
  );
};

export default App;
