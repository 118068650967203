import React from 'react';

const Header = () => {
  return (
    <header className="bg-customBlack text-customWhite py-1">
      <div className="container h-4 mx-auto flex items-center md:justify-end justify-around  md:gap-20">
        <div className="flex items-center mb-2 md:mb-0 md:mr-4">
          <img src="Main_Banner/Call icon.png" alt="Call Icon" className="mr-2" />
          <span className="text-xs md:text-base">IN +91 9971133757</span>
        </div>
        <div className="flex items-center">
          <img src="Main_Banner/Mail icon.png" alt="Mail Icon" className="mr-2" />
          <span className="text-xs md:text-base">admin@lytblbtechnology.com</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
