import React from 'react';

const OurClients = () => {
  return (
    <div id="ourclients" className="bg-gray-100 flex flex-col items-center py-8">
      <div className="text-center mb-6">
        <h1 className="text-5xl font-bold ">
          <span className="text-customBlue">50+</span> Businesses Thriving with LYTBLB
        </h1>
      </div>
      <div className="text-center mb-6">
        <p className="text-customBlack font-semibold">
          Whether you’re starting fresh or expanding digitally, LYTBLB stands ready to assist.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-8 lg:gap-12">
  <img 
    className="w-48 h-44 md:w-36 md:h-36 lg:w-52 lg:h-52 object-cover mx-auto" 
    src="Clients/5.jpg" 
    alt="Client 1" 
  />
  <img 
    className="w-48 h-44 md:w-36 md:h-36 lg:w-52 lg:h-52 object-cover mx-auto" 
    src="Clients/4.jpg"  
    alt="Client 2" 
  />
  <img 
    className="w-48 h-44 md:w-36 md:h-36 lg:w-52 lg:h-52 object-cover mx-auto" 
    src="Clients/3.jpg"  
    alt="Client 3" 
  />
  <img 
    className="w-48 h-44 md:w-36 md:h-36 lg:w-52 lg:h-52 object-cover mx-auto" 
    src="Clients/2.jpg" 
    alt="Client 4" 
  />
  <img 
    className="w-48 h-44 md:w-36 md:h-36 lg:w-52 lg:h-52 object-cover mx-auto" 
    src="Clients/1.jpg" 
    alt="Client 5" 
  />
</div>

    </div>
  );
};

export default OurClients;
