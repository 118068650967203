import React from 'react';

const WhoWeAre = () => {
  const waveFormStyle = `
    .custom-shape-divider-bottom-1718281755 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;
      transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1718281755 svg {
      position: relative;
      display: block;
      width: calc(101% + 1.3px);
      height: 60px;
      transform: rotateY(180deg);
    }

    .custom-shape-divider-bottom-1718281755 .shape-fill {
      fill: #FFFFFF;
    }
  `;

  return (
    <section id='who' className="relative bg-customBlue py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-customWhite font-playfair sm:text-5xl">Who We Are</h2>
          <p className="mt-4 text-3xl leading-9 text-customWhite font-playfair ">
            LYTBLB Technology is specialize in web scraping, website, and mobile app development, 
            offering streamlined solutions tailored to your needs. With years of experience and a passion 
            for innovation, we make the complex simple. From extracting insights to crafting intuitive 
            platforms, we’re your trusted partner in navigating the digital landscape. Let’s collaborate to 
            turn your ideas into reality.
          </p>
        </div>
        {/* Cards Section */}
        <div className="mt-12 text-center">
          <h2 className="text-3xl font-bold text-customWhite  font-playfair sm:text-5xl">Our Services</h2>
          <div className="mt-10 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-customWhite shadow-md rounded-lg p-6">
              <h3 className="text-2xl font-semibold text-customBlack">Web Scraping</h3>
              <img src="Our Services/Data Scraping@2x.png" className="w-[50%] my-2 mx-auto rounded-full" alt="Web Scraping" />
              <p className="md:mt-4 font-normal text-customBlack">
                We unlock the power of the web by specializing in web scraping. We act as your data extraction experts, building tools and solutions to gather valuable information from websites.
              </p>
            </div>
            <div className="bg-customWhite shadow-md rounded-lg p-6">
              <h3 className="text-2xl font-semibold text-customBlack">Website Development</h3>
              <img src="Our Services/Website Developement@2x.png" className="w-[50%]  my-2 mx-auto
                rounded-full" alt="Website Development" />
              <p className="md:mt-4 font-normal   text-customBlack">
                We craft exceptional websites that not only look stunning, but also deliver powerful functionality. Our team of passionate developers combines cutting-edge technology with strategic planning to build websites that convert visitors into customers.
              </p>
            </div>
            <div className="bg-customWhite shadow-md rounded-lg p-6">
              <h3 className="text-2xl font-semibold text-gray-900">Mobile App Development</h3>
              <img src="Our Services/Mobile App Development@2x.png" className="w-[50%] my-2 mx-auto rounded-full" alt="Mobile App Development" />
              <p className="md:mt-4 font-normal text-customBlack">
                We turn your innovative mobile app ideas into reality. We don’t just code – we craft user-centric experiences. From sleek design and intuitive interfaces to powerful features and seamless functionality, we build apps that users love to use.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Waveform */}
      <style>{waveFormStyle}</style>
      <div className="custom-shape-divider-bottom-1718281755">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>
    </section>
  );
}

export default WhoWeAre;
