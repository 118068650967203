import React from 'react';

const LYTBLBOverview = () => {
  return (
    <div className="bg-customBlue p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-5xl text-customWhite font-bold mb-8 text-center">LYTBLB Overview</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="flex flex-col items-center text-customWhite">
            <img 
              loading="lazy" 
              decoding="async" 
              width="72" 
              height="72" 
              src="Works/clients.png" 
              alt="Satisfied Clients" 
              className="mb-4 "
            />
            <p className='font-semibold text-1xl text-center'>50+ Satisfied Clients</p>
          </div>
          <div className="flex flex-col items-center text-customWhite">
            <img 
              loading="lazy" 
              decoding="async" 
              width="73" 
              height="73" 
              src="Works/User_friendly.png" 
              alt="User-friendly interface" 
              className="mb-4"
            />
            <p className='font-semibold text-1xl text-center'>User-friendly interface</p>
          </div>
          <div className="flex flex-col items-center text-customWhite">
            <img 
              loading="lazy" 
              decoding="async" 
              width="72" 
              height="72" 
              src="Works/24-7-support.png" 
              alt="24/7 availability of assistants" 
              className="mb-4 "
            />
            <p className='font-semibold text-1xl text-center'>24/7 availability of assistants</p>
          </div>
          <div className="flex flex-col items-center text-customWhite">
            <img 
              loading="lazy" 
              decoding="async" 
              width="72" 
              height="72" 
              src="Works/customer.png"
              alt="Serving a vast user base of 10 million customers" 
              className="mb-4 "
            />
            <p className='font-semibold text-1xl text-center'>Serving a vast user base of 10 million customers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LYTBLBOverview;
