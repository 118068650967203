// src/ContactForm.js
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

// Initialize EmailJS with your public key
emailjs.init("qZs9dRvAUSUow-ebX");

const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });

  const subject_name = "LYTBLB TECHNOLOGY";

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      message: formData.message,
      subject_name: subject_name, // Adding subject_name here
    };

    emailjs
      .send(
        "service_fy4qpi4",
        "template_u7e020c",
        templateParams
      )
      .then(
        () => {
          console.log("SUCCESS!");
          alert("Message sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Failed to send message, please try again.");
        }
      );

    setFormData({
      from_name: "",
      from_email: "",
      message: "",
    });
  };
  const handleCancel = () => {
    setFormData({
      from_name: "",
      from_email: "",
      message: "",
    });
  };

  return (
    <div id="contactus" className="flex flex-col items-center justify-around h-full">
      <div className="my-3">
        <h2 className="font-sans font-bold text-customBlack text-lg sm:text-xl md:text-5xl">
          Send Us A Message
        </h2>
      </div>

      <p className=" text-lg font-normal text-customgrey text-center ">
        Unlock Answers To Your Thoughts And Queries.
      </p>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="w-[80%] md:w-[50%] space-y-6"
      >
        <div>
          <label
            className="block text-customBlack font-medium text-base mb-2"
            htmlFor="from_name"
          >
            Name *
          </label>
          <input
            type="text"
            id="from_name"
            autoComplete="off"
            name="from_name"
            placeholder="Enter Your Full Name"
            value={formData.from_name}
            onChange={handleChange}
            className="w-full bg-transparent border-b-2 border-customgrey py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label
            className="block text-customBlack font-medium text-base mb-2"
            htmlFor="from_email"
          >
            Email *
          </label>
          <input
            type="email"
            id="from_email"
            name="from_email"
            placeholder="Enter Your Email Id"
            value={formData.from_email}
            onChange={handleChange}
            className="w-full bg-transparent border-b-2 border-customgrey py-2 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label
            className="block text-customBlack font-medium text-base mb-2"
            htmlFor="message"
          >
            Message*
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Write Message Here"
            value={formData.message}
            onChange={handleChange}
            className="w-full bg-transparent border-b-2 border-customgrey py-2 text-white focus:outline-none focus:border-blue-500"
            rows="1"
            required
          ></textarea>
        </div>
        <div className="flex flex-col text-center items-center gap-2">
          <button
            type="submit"
            className="text-customWhite font-montserrat bg-customBlue hover:bg-customBlue focus:outline-none  font-medium rounded-full text-lg px-20 py-2  md:w-[100%] text-center me-2 "
          >
            Send
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className=" px-2 py-2 text-white rounded-lg font-semibold  "
          >
            CANCEL
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;