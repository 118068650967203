import React from 'react';
import { CiMail } from "react-icons/ci";
import { IoIosCall } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from 'react-scroll'

const Footer = () => {
  return (
    <footer className="bg-footergrey text-customWhite p-4 md:h-80">
      <div className="flex flex-col md:flex-row justify-around   my-10">

        <div className="mb-0 md:mb-10 mt-4  md:mx-auto ml-12 ">
          <h5 className="text-2xl font-medium mb-4 ">Quick Links</h5>
          <nav>
          <ul >
          <li className='text-lg font-medium my-2'>
                  <Link   
                         to="who" className='cursor-pointer text-xl mb-2' smooth={true} duration={1000}> Who We Are</Link>
                  </li>
                  <li className='text-lg font-medium my-2'>
                  <Link to="services" className='cursor-pointer text-xl mb-2' smooth={true} duration={1000} > How We Work</Link>
                  </li>
                  <li className='text-lg font-medium my-2'>
                  <Link to="ourclients" className='cursor-pointer text-xl mb-2'smooth={true} duration={1000}>Our Clients </Link>
                  </li>
                  <li className='text-lg font-medium my-2'>
                  <Link to="contactus"className='cursor-pointer text-xl mb-2'  smooth={true} duration={1000}> Contact Us </Link>
                  </li>
                </ul>

          </nav>
        </div>
        <div className="mb-6 md:mb-8 mt-4  md:mx-auto ml-12  ">
          <h5 className="text-2xl font-semibold mb-4">Our Services</h5>
          <ul >
                  <li className='text-lg font-medium my-2'>
                  <Link   
                         spy={true} className='cursor-pointer text-xl 'smooth={true}duration={1000}to="scrap"> Web Scraping</Link>
                  </li>
                
                  <li className='text-lg font-medium my-2'>
                  <Link to="develop" className='cursor-pointer my-2 text-xl' spy={true} smooth={true} duration={1000} > Website Development</Link>
                  </li>
                 
                  <li className='text-lg font-medium my-2'>
                  <Link to="mobile" className='cursor-pointer text-xl  ' spy={true} smooth={true} duration={1000}>Mobile App Development</Link>
                   </li>
                 
                </ul>
        </div>
        <div className="mb-8 md:mb-8 mt-4 md:ml-0  ml-12">
          <h5 className="text-2xl font-medium ">Contact Us</h5>
          <div className="">
            <div className="flex items-center my-2 gap-2 ">
            <CiMail className="text-customBlue size-5"/>
              
              <span className='text-lg font-medium '>admin@lytblbtechnology.com</span>
            </div>
            <div className="flex items-center  my-2 gap-2">
            <IoIosCall  className="text-customBlue size-5"/>
             
              <span className='text-base font-medium'>IN +91 9971133757</span>
            </div>
            <div className="flex items-center gap-2 ">
            <FaLocationArrow className="text-customBlue size-4"/>
           
              <span className='text-lg font-medium'>Mundka, New-Delhi -110041</span>
            </div>
          </div>
        </div> 


  
       


        <div className="flex flex-col   mx-auto mt-2  ">
          <a href="https://lytblbtechnology.com">
            <img
              width="132"
              height="132"
              src="Footer/Logo with white text@2x.png"
              alt="Lytblb Technology"
              className="mb-4"
            />
          </a>
          
        
        </div>
     
        </div>
    </footer>
  );
};

export default Footer;
