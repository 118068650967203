import React, { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { FaArrowRight } from "react-icons/fa";

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email && email.indexOf("@") > -1 && onValidated({ EMAIL: email });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  return (
    <div className="flex flex-col  md:w-full ">
      <form className="flex bg-customWhite rounded-lg" onSubmit={handleSubmit}>
  <input
    type="email"
    name="email"
    id="email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    className="w-full p-2 pr-12 rounded-lg bg-customWhite  border-customWhite focus:ring-0"
    placeholder="Enter email id here.."
    required
    aria-required="true"
  />
  <button type="submit" className="bg-customWhite px-3 rounded-lg">
    <FaArrowRight />
  </button>
</form>


      <div className="">
        {status === "sending" && <p className="text-customWhite justify-center font-bold mt-2">Sending...</p>}
        {status === "error" && (
          <p
            className="text-[1.1rem] mt-2 font-bold text-customRed justify-center"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <p
            className="text-[1.1rem] justify-center mt-2 font-bold text-customWhite"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </div>

  );
};

const SubscribeSection = () => {
  const postUrl =
    "https://lytblbtechnology.us17.list-manage.com/subscribe/post?u=26e97079f15cb73f3fc7bb62a&id=bd350de8e2";

  return (
    <div className="bg-customBlue flex justify-center items-center py-8">
      <div className="w-full max-w-4xl flex flex-col  md:flex-row items-center">
        {/* Text Content Section */}
        <div className=" w-full md:w-1/2 mb-4">
          <h3 className="text-3xl font-bold text-center font-sans md:mr-18 text-customWhite md:text-left">
            Subscribe to our E-mails For Latest News & Updates
          </h3>
        </div>

        {/* Input and Submit Section */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscribeSection;