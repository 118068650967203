import React from 'react';
import { FaCheckCircle } from "react-icons/fa";

const Services = () => {
  return (
    <section id="services" className="p-8 bg-gray-100">
      <h1 className="text-5xl font-bold mb-4 text-center">How We Work</h1>

      <div className="flex flex-wrap md:flex-nowrap items-center justify-center md:justify-between">
        <div className="w-full md:w-1/2 md:pr-4 mt-10">
          <h2 id="scrap" className="text-4xl font-bold mb-4 text-left">#1 Web Scraping</h2>
          <p className="text-[110%]">Unlocking valuable insights from intricate web structures is effortless with our data extraction service</p>
          <div className="flex justify-center mt-6">
            <img src="How We Work/Data Scraping Work@2x.png" alt="Services" className="w-full md:w-[75%]" />
          </div>
        </div>

        <div className="w-full md:w-1/2 md:mt-32 text-left">
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Simple Interface:</h3></span>
            <p className='text-[120%] ml-10'>No coding needed. Just point and click to extract data effortlessly.</p>
          </div>
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Dynamic Websites:</h3></span>
            <p className='text-[120%] ml-10'>Extract data from complex sites, including those using JavaScript.</p>
          </div>
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Customized Extraction:</h3></span>
            <p className='text-[120%] ml-10'>Tailor extraction using sitemaps for precise data retrieval</p>
          </div>
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Flexible Export:</h3></span>
            <p className='text-[120%] ml-10'>Export data in CSV, XLSX, or JSON formats for seamless integration.</p>
          </div>
        </div>
      </div>




{/* Second Service */}


      <div className="flex flex-wrap md:flex-nowrap items-center justify-center md:justify-between mt-10">
        <div className="w-full md:w-1/2 md:pr-6">
          <h2 id='develop' className="text-4xl font-bold mb-4 text-left">#2 Website Development</h2>
          <p className="text-[110%]">Crafting responsive, user-centric websites for optimal digital presence and engagement</p>
          <div className="mt-8">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Custom Website Design:</h3></span>
            <p className='text-[120%] ml-10'>Tailored solutions reflecting brand identity, enhancing user experience, driving engagement.</p>
          </div>
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Responsive Development:</h3></span>
            <p className='text-[120%] ml-10'>Prioritize flawless functionality across all devices, captivating audiences effectively.</p>
          </div>
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">E-commerce Integration:</h3></span>
            <p className='text-[120%] ml-10'>Maximize online sales, showcase products seamlessly, facilitate smooth transactions.</p>
          </div>
          <div className="mt-4">
            <span className='flex gap-2'><FaCheckCircle className="text-iconblue size-7" /> <h3 className="text-2xl font-semibold">Content Management Systems (CMS):</h3></span>
            <p className='text-[120%] ml-10'>Effortlessly manage website content, maintain freshness, relevance, engage audience effectively.</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 mt-12 text-center">
          <img src="How We Work/Website Development Work@2x.png" alt="Services" className="w-full md:mt-24 md:w-[75%]" />
        </div>
      </div>

      {/* Third Services */}

      <div className="flex flex-wrap md:flex-nowrap items-center justify-center md:justify-between">
         {/* <!-- Image Content --> */}
        <div className="w-full md:w-1/2 md:pr-4 mt-10">
          <h2 id="scrap" className="text-4xl font-bold mb-4 text-left">#3 Mobile App Development</h2>
          <p className="text-[110%]">Tailored iOS/Android apps, intuitive design, agile process, ensuring cross-platform compatibility for clients.</p>
          <div className="flex justify-center mt-6">
            <img src="How We Work/Mobile App Development Work@2x.png" alt="Services" className="w-full md:w-[75%]" />
          </div>
        </div>
{/* <!-- Text Content --> */}
<div className="w-full md:w-1/2 md:mt-32 text-left">
  <div className="mt-4">
    <span className='flex gap-2'>
      <FaCheckCircle className="text-iconblue w-7 h-7" /> 
      <h3 className="text-2xl font-semibold">Custom Mobile App Development:</h3>
    </span>
    <p className='text-[120%] ml-10'>Tailored apps for iOS and Android, meeting unique business needs.</p>
  </div>
  <div className="mt-4">
    <span className='flex gap-2'>
      <FaCheckCircle className="text-iconblue w-7 h-7" /> 
      <h3 className="text-2xl font-semibold">User-Centric Design:</h3>
    </span>
    <p className='text-[120%] ml-10'>Intuitive interfaces ensuring engaging user experiences.</p>
  </div>
  <div className="mt-4">
    <span className='flex gap-2'>
      <FaCheckCircle className="text-iconblue w-7 h-7" /> 
      <h3 className="text-2xl font-semibold">Agile Development Process:</h3>
    </span>
    <p className='text-[120%] ml-10'>Rapid iterations for timely delivery and continuous improvement.</p>
  </div>
  <div className="mt-4">
    <span className='flex gap-2'>
      <FaCheckCircle className="text-iconblue w-7 h-7" /> 
      <h3 className="text-2xl font-semibold">Cross-Platform Compatibility:</h3>
    </span>
    <p className='text-[120%] ml-10'>Apps optimized for seamless performance across devices.</p>
  </div>
</div>
</div>

    </section>
  );
};

export default Services;
