import React from 'react';
import ContactForm from './utilities/ContactForm';

const ContactUs = () => {
  return (
    <div className="lg:h-[80vh] bg-customWhite w-full">
        <ContactForm></ContactForm>
      </div>
   
  );
};

export default ContactUs;


