import React from 'react';
import { Link } from 'react-scroll';

const Page = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-around h-screen md:h-[80vh]">
      {/* Left Column (Text Content) */}
      <div className="w-full md:w-[55%] flex flex-col items-center md:items-start mt-5 md:mt-0  md:ml-20">
        <h1 className="text-3xl md:text-[3.3rem] text-customBlue font-montserrat font-bold leading-snug text-center md:text-center mr-2">
          Effortlessly Simplifying
        </h1>
        <h3 className="text-2xl md:text-[3.3rem] font-normal font-sans leading-snug mt-3  text-center md:text-left mr-2">
          Web Scraping, Website, <br/>Mobile App Development
          <br />
          <span className="italic">Your One-Click Solution!</span>
        </h3>
        <button className="bg-customBlue text-customWhite w-2/3 md:w-1/3 mt-5 p-2 pr-2 text-xl font-sans text-white md:mx-16 rounded-full">
          <Link spy={true} smooth={true} duration={1000} to="contactus" className="cursor-pointer block w-full h-full text-center">
            Get Started
          </Link>
        </button>
      </div>
      
      {/* Right Column (Image) */}
      <div className="w-full md:w-[45%] flex justify-start mt-5 md:mt-0">
        <img src="Main_Banner/Main Banner@2x.png" alt="Banner" className="h-96 md:mt-5  md:ml-5" />
      </div>
    </div>
  );
};

export default Page;
